import axios from 'axios';

class ProviderPages {
  BASE_URL = 'https://api.therenceguillerme.fr/wp-json/acf/v3';

  getPage(id) {
    return axios.get(`${this.BASE_URL}/pages/${id}`);
  }
}

export default new ProviderPages();
