import styled from 'styled-components';
import React, { Component } from 'react';
import SuperMath from '../../utils/SuperMath';
import { TweenMax } from 'gsap';
import mediumZoom from 'medium-zoom';

const Images = styled.div`
  display: flex;
  z-index: 1;
`;

const ImageItem = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const ImageDescription = styled.p`
  font-family: Raleway;
  font-style: italic;
  font-weight: 500;
  line-height: normal;
  font-size: 14px;

  color: rgba(255, 255, 255, 0.5);
  margin-top: 29px;
  width: 731px;

  @media all and (max-width: 1024px) {
    width: 100%;
  }
`;

const LeftButton = styled.button`
  position: absolute;
  left: 0;
  bottom: 0;
  margin-left: -69px;
  z-index: 5;
  height: 18px;
  @media all and (max-width: 1024px) {
    top: -49px;
    margin-left: 0px;
  }
`;
const RightButton = styled.button`
  position: absolute;
  right: 0;
  bottom: 0;
  margin-right: -69px;
  z-index: 5;
  height: 18px;
  @media all and (max-width: 1024px) {
    top: -49px;
    margin-right: 0px;
  }
`;

const Slider = styled.div`
  width: 731px;
  height: 421px;
  display: flex;
  margin: auto;
  justify-content: flex-start;
  position: relative;
  @media all and (max-width: 1024px) {
    width: 100%;
    height: 380px;
  }
  @media all and (max-width: 380px) {
    height: 233px;
  }
`;

const SliderWrapper = styled.div`
  position: relative;
  height: 593px;
  display: flex;
  @media all and (max-width: 1024px) {
    padding: 0 20px;
    height: 380px;
  }
`;

const SliderBackground = styled.div`
  background: #232323;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
`;

const SlideItem = styled.div`
  filter: grayscale(100%);
  position: absolute;
  height: 421px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  width: 100%;
  @media all and (max-width: 1024px) {
    height: 380px;
  }
`;

const Indicator = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-size: 16px;
  text-align: center;

  color: #0019ff;

  display: none;

  @media all and (max-width: 1024px) {
    display: block;
  }
`;

const IndicatorWrapper = styled.div`
  width: 100%;
  position: absolute;
  top: -49px;
  display: flex;
  justify-content: center;
  z-index: 1;
`;

export default class PortfolioImageSlider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentSlide: 0,
      zoom: mediumZoom({
        background: '#000',
        margin: 20,
      }),
    };
    this.$images = React.createRef();
    this.openLightbox = this.openLightbox.bind(this);
  }

  openLightbox(e) {
    this.state.zoom.attach(e.target);
  }

  componentDidMount() {
    [...this.$images.current.children].map((slide, index) => {
      let x = 0;
      for (let i = 0; i < index; i++) {
        if (i !== this.state.currentSlide) {
          x += 731 * 0.25 + 20;
        } else {
          x += 731 + 20;
        }
      }

      let currentSlideX = 0;
      for (let i = 0; i < this.state.currentSlide; i++) {
        if (i !== this.state.currentSlide) {
          currentSlideX += 731 * 0.25 + 20;
        } else {
          currentSlideX += 731 + 20;
        }
      }

      x -= currentSlideX;

      TweenMax.set(slide, {
        x,
        scale: index === this.state.currentSlide ? 1 : 0.25,
        transformOrigin: 'top left',
        filter: index === this.state.currentSlide ? 'none' : 'grayscale(100%)',
      });

      TweenMax.set(slide.children[1], {
        opacity: this.state.currentSlide === index ? 1 : 0,
      });
      return slide;
    });
  }

  nextSlide() {
    this.setState(
      {
        currentSlide: SuperMath.clamp(this.state.currentSlide + 1, 0, this.props.images.length - 1),
      },
      () => {
        this.updateSlides();
      },
    );
  }

  updateSlides() {
    [...this.$images.current.children].map((slide, index) => {
      let x = 0;
      for (let i = 0; i < index; i++) {
        if (i !== this.state.currentSlide) {
          x += 731 * 0.25 + 20;
        } else {
          x += 731 + 20;
        }
      }

      let currentSlideX = 0;
      for (let i = 0; i < this.state.currentSlide; i++) {
        if (i !== this.state.currentSlide) {
          currentSlideX += 731 * 0.25 + 20;
        } else {
          currentSlideX += 731 + 20;
        }
      }

      x -= currentSlideX;

      TweenMax.set(slide, {
        x,
        scale: index === this.state.currentSlide ? 1 : 0.25,
        transformOrigin: 'top left',
        filter: index === this.state.currentSlide ? 'none' : 'grayscale(100%)',
      });

      TweenMax.set(slide.children[1], {
        opacity: this.state.currentSlide === index ? 1 : 0,
      });
      return slide;
    });
  }

  previousSlide() {
    this.setState(
      {
        currentSlide: SuperMath.clamp(this.state.currentSlide - 1, 0, this.props.images.length - 1),
      },
      () => {
        this.updateSlides();
      },
    );
  }

  render() {
    return (
      <SliderWrapper className={this.props.className}>
        <Slider>
          <IndicatorWrapper>
            <Indicator>
              {this.state.currentSlide + 1}/{this.props.images.length}
            </Indicator>
          </IndicatorWrapper>
          <LeftButton onClick={this.previousSlide.bind(this)}>
            <svg width='45' height='18' viewBox='0 0 45 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M2.58309 9.25549L44 9.25549'
                stroke='#FF7A00'
                strokeWidth='2'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M9.89252 1.80615L1.00006 9.25556L9.89252 16.705'
                stroke='#FF7A00'
                strokeWidth='2'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </LeftButton>
          <Images ref={this.$images}>
            {this.props.images.map(image => (
              <SlideItem key={image.id} onClick={this.openLightbox}>
                <ImageItem src={image.url} />
                <ImageDescription>{image.caption}</ImageDescription>
              </SlideItem>
            ))}
          </Images>
          <RightButton onClick={this.nextSlide.bind(this)}>
            <svg width='45' height='18' viewBox='0 0 45 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M42.4169 9.25561L1 9.25562'
                stroke='#FF7A00'
                strokeWidth='2'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M35.1074 16.705L43.9999 9.25555L35.1074 1.80614'
                stroke='#FF7A00'
                strokeWidth='2'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </RightButton>
        </Slider>
        <SliderBackground />
      </SliderWrapper>
    );
  }
}
