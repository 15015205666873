import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {Trans} from "react-i18next";

i18n.use(LanguageDetector).init({
	// we init with resources
	resources: {
		fr: {
			translations: {
				"Voir mon Portfolio": "Voir mon portfolio",
				"Portfolio": "Portfolio",
				"À propos": "À propos",
				"Actualités": "Actualités",
				"Contact": "Contact",
				"Retrouvez prochainement mon Press Book": "Retrouvez prochainement mon Press Book",
				"contenant tous mes projets": "contenant tous mes projets",
				"Dernières actualités": "Dernières actualités",
				"Découvrir": "Découvrir",
				"Toutes les actus": "Toutes les actus",
				"Mentions légales": "Mentions légales",
				"Tout": "Tout",
				"Animations": "Animations",
				"Films et séries": "Films et séries",
				"Jeux vidéo": "Jeux vidéo",
				"Installations sonores": "Installations sonores",
				"Compositions": "Compositions",
				"Autres": "Autres",
				"Contactez-moi": "Contactez-moi",
				"Nom et prénom": "Nom et prénom",
				"Mail": "Mail",
				"Objet": "Objet",
				"Message": "Message",
				"Fermer": "Fermer",
				"Retour": "Retour",
				"Filtres": "Filtres",
				"Je n’ai pas encore de projet dans cette catégorie ...": "Je n’ai pas encore de projet dans cette catégorie ...",
				"Choisissez-en une autre": "Choisissez-en une autre",
				"ou contactez moi pour discuter !": "ou contactez moi pour discuter !",
				"Télécharger": "Télécharger",
				"Téléchargez mon Press Book": "Téléchargez mon Press Book",
				"Envoyer !": "Envoyer !",
				"Retour au Portfolio": "Retour au Portfolio",
			}
		},
		en: {
			translations: {
				"Voir mon Portfolio": "Go to portfolio",
				"Portfolio": "Portfolio",
				"À propos": "About",
				"Actualités": "News",
				"Contact": "Contact",
				"Retrouvez prochainement mon Press Book": "Soon my book here",
				"contenant tous mes projets": "with all my projects",
				"Dernières actualités": "Latest news",
				"Découvrir": "Discover",
				"Toutes les actus": "All news",
				"Mentions légales": "Legal notice",
				"Tout": "All",
				"Animations": "Animations",
				"Films et séries": "Movie et TV shows",
				"Jeux vidéo": "Video games",
				"Installations sonores": "Sound installations",
				"Compositions": "Compositions",
				"Autres": "Miscellaneous",
				"Contactez-moi": "Contact me",
				"Nom et prénom": "Name",
				"Mail": "Mail",
				"Objet": "Subject",
				"Message": "Message",
				"Fermer": "Close",
				"Retour": "Back",
				"Filtres": "Filters",
				"Je n’ai pas encore de projet dans cette catégorie ...": "I don't have any project on this category ...",
				"Choisissez-en une autre": "Choose another one",
				"ou contactez moi pour discuter !": "or contact me to talk !",
				"Télécharger": "Download",
				"Téléchargez mon Press Book": "Download my Press Book",
				"Envoyer !": "Send !",
				"Retour au Portfolio": "Back to Portfolio",
			}
		}
	},
	fallbackLng: "fr",
	debug: true,

	// have a common namespace used around the full app
	ns: ["translations"],
	defaultNS: "translations",

	keySeparator: false, // we use content as keys

	interpolation: {
		escapeValue: false, // not needed for react!!
		formatSeparator: ","
	},

	react: {
		wait: true
	}
});

export default i18n;
