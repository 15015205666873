import React, { Component } from 'react';
import { Link } from "react-router-dom";
import SoundCloudLogo from '../assets/images/logo_soundcloud.svg';
import FacebookLogo from '../assets/images/logo_facebook.svg';
import { translate, Trans } from "react-i18next";

class Footer extends Component {
  render() {
    return (
      <footer>
        <nav>
          <ul>
            <li className="mentions">
              <Link to="/mentions-legales">
                <Trans>
                  Mentions légales
                </Trans>
              </Link>  <span>•  © Thérence Guillerme</span></li>
            <li>
              <a href="https://soundcloud.com/therence-guillerme">
                <img src={SoundCloudLogo} className="soundcloud" alt="Soundcloud"/>
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/TherenceGuillermeComposition/?modal=admin_todo_tour">
                <img src={FacebookLogo} className="facebook" alt="Facebook"/>
              </a>
            </li>
          </ul>
        </nav>
      </footer>
    );
  }
}

export default translate("translations")(Footer);
