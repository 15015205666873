import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class AppButton extends Component {
  render() {
    return (
      <Link
        style={this.props.style}
        className={"app-button " + this.props.className}
        to={this.props.href}
      >
        {this.props.children}
      </Link>
    );
  }
}
