import React, { Component } from 'react';
import ProviderOptions from '../providers/options';
import Swiper from 'swiper';
import 'swiper/dist/css/swiper.min.css';
import PortfolioDetailVideo from './PortfolioDetail/PortfolioDetailVideo';
import PortfolioAudioPlayer from './PortfolioDetail/PortfolioAudioPlayer';

class ShowReel extends Component {
  constructor(props) {
    super(props);
    this.swiper = null;

    this.state = {
      slides: [],
    };

    ProviderOptions.getOptions().then(data => {
      const slides = data.data.acf.showreel;
      this.setState({
        slides: slides,
      });
      this.swiper.updateSlides();
      this.swiper.update();
    });

    this.swiperRef = React.createRef();
    this.mediaElement = React.createRef();
  }

  componentDidMount() {
    this.swiper = new Swiper(this.swiperRef.current, {
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  }

  render() {
    return (
      <div className='showreel'>
        <h2>Show reel</h2>
        <div className='swiper-container' ref={this.swiperRef}>
          <div className='swiper-wrapper'>
            {this.state.slides.map((slide, idx) => (
              <div className='swiper-slide' key={idx}>
                {slide.url.includes('soundcloud') ? (
                  <PortfolioAudioPlayer ref={this.mediaElement} credits='' source={slide.url} />
                ) : (
                  <PortfolioDetailVideo ref={this.mediaElement} credits='' source={slide.url} />
                )}
              </div>
            ))}
          </div>
          {/* <div className='swiper-button-prev'></div>
          <div className='swiper-button-next'></div> */}
        </div>
      </div>
    );
  }
}

export default ShowReel;
