import React, { Component } from 'react';
import Logo from '../assets/images/logo_white_home.svg';
import Photo from '../assets/images/picture_home.jpg';
import { Link } from 'react-router-dom';
import ProviderNews from '../providers/news';
import ProviderProject from '../providers/projects';
import GridItem from './GridItem';
import ShowReel from './ShowReel';
import Book from './Book';
import References from './References';
import * as moment from 'moment';
import { translate, Trans } from 'react-i18next';
import Project from './Project';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      news: [],
      projects: [],
    };

    ProviderNews.getAllNews().then(data => {
      const news = data.data;
      news.sort((a, b) => {
        const left = moment(a.acf.date, 'DD/MM/YYYY');
        const right = moment(b.acf.date, 'DD/MM/YYYY');
        return left < right ? 1 : left > right ? -1 : 0;
      });
      this.setState({ news });
      this.setState({
        news: news.slice(0, 2),
      });
    });
    this.formatDate = this.formatDate.bind(this);

    ProviderProject.getProjects().then(data => {
      const projects = data.data;
      projects.sort((a, b) => {
        const left = moment(a.acf.date, 'DD/MM/YYYY');
        const right = moment(b.acf.date, 'DD/MM/YYYY');
        return left < right ? 1 : left > right ? -1 : 0;
      });
      //this.setState({ projects });
      this.setState({
        projects: projects.slice(0, 6),
      });
      console.log(projects);
    });
  }

  formatDate(date) {
    const d = new Date(date);
    return ('0' + d.getDate()).slice(-2) + '/' + ('0' + d.getMonth() + 1).slice(-2) + '/' + d.getFullYear();
  }
  render() {
    return (
      <div id='home'>
        <section className='home__top'>
          <img src={Photo} className='main__photo' alt='Photo' />
          <div className='content'>
            <img className='main__logo' src={Logo} alt='Thérence Guillerme' />
            <h1>Compositeur à l’image & sound designer</h1>
            <Link to='/portfolio' className='btn btn-xl'>
              <i className='icon icon-fleche_complete' />
              <Trans>Voir mon Portfolio</Trans>
            </Link>
          </div>
        </section>
        <Book />
        <ShowReel />
        <section className='home__projects'>
          <h2 className='last-projects'>
            <Trans>Derniers projets</Trans>
          </h2>

          <div className='projects__list project-list'>
            {this.state.projects.map((project, index) => (
              <Project key={index} title={project.acf.title} type={project.acf.type} id={project.id} thumbnail={project.acf.thumbnail} />
            ))}
          </div>

          <Link to='/portfolio' className='btn'>
            <i className='icon icon-fleche_complete' />
            <Trans>Tous mes projets</Trans>
          </Link>
        </section>
        <section className='home__news'>
          <div className='content'>
            <h2 className='last-news'>
              <Trans>Dernières actualités</Trans>
            </h2>

            <div className='news__list'>
              {this.state.news.map(news => (
                <GridItem
                  title={news.title.rendered}
                  type={this.formatDate(news.date)}
                  image={news.featured_image_url}
                  url={`/news/${news.id}`}
                  key={news.id}
                />
              ))}
            </div>

            <div className='portfolio'>
              <Link to='/news' className='btn'>
                <i className='icon icon-fleche_complete' />
                <Trans>Toutes les actus</Trans>
              </Link>
            </div>
          </div>
        </section>
        <References />
      </div>
    );
  }
}

export default translate('translations')(App);
