import React, { Component } from 'react';
import GridItem from './GridItem';
import { Link } from 'react-router-dom';
import Book from './Book';
import ProviderProjects from '../providers/projects';
import * as moment from 'moment';
import { Trans, translate } from 'react-i18next';
import Project from './Project';

class Portfolio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projects: [],
      filter: 'all',
      filteredProjects: [],
      showFilters: false,
      filters: [
        {
          slug: 'all',
          title: 'Tout',
        },
        {
          slug: 'animation',
          title: 'Animations',
        },
        {
          slug: 'movie',
          title: 'Films et séries',
        },
        {
          slug: 'game',
          title: 'Jeux vidéo',
        },
        {
          slug: 'installation',
          title: 'Installations sonores',
        },
        {
          slug: 'composition',
          title: 'Composition',
        },
        ,
        {
          slug: 'other',
          title: 'Autres',
        },
      ],
    };

    ProviderProjects.getProjects().then(data => {
      console.log(data.data);
      const projects = data.data;
      projects.sort((a, b) => {
        const left = moment(a.acf.date, 'DD/MM/YYYY');
        const right = moment(b.acf.date, 'DD/MM/YYYY');
        return left < right ? 1 : left > right ? -1 : 0;
      });
      this.setState({ projects });
      this.onFilterClick('all');
    });
    this.onClickTagMenu = this.onClickTagMenu.bind(this);
    this.handleContact = this.handleContact.bind(this);
  }

  onFilterClick(filter) {
    this.setState(
      {
        filter: filter,
      },
      () => {
        this.filterProjects();
      },
    );
  }

  filterProjects() {
    const filteredProjects = this.state.projects.filter(item => {
      return this.state.filter == 'all' || this.state.filter == item.acf.tag;
    });
    this.setState({
      filteredProjects,
    });
  }

  handleContact() {
    const event = new Event('contact:open');
    window.dispatchEvent(event);
  }

  onClickTagMenu() {
    this.setState({
      showFilters: !this.state.showFilters,
    });
  }

  render() {
    return (
      <div id='portfolio' className='page'>
        <div className={this.state.filteredProjects.length === 0 ? 'header no-margin' : 'header'}>
          <div className='content'>
            <h1>
              <Trans>Portfolio</Trans>
            </h1>
            <div className={this.state.showFilters ? 'tags-menu opened' : 'tags-menu'} onClick={this.onClickTagMenu}>
              <Trans>Filtres</Trans>
            </div>
            <div className='tags'>
              {this.state.filters.map((filter, index) => (
                <div
                  key={index}
                  className={this.state.filter === filter.slug ? 'tag tag--selected' : 'tag'}
                  onClick={this.onFilterClick.bind(this, filter.slug)}
                >
                  <Trans>{filter.title}</Trans>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className='projects'>
          <div className='list project-list'>
            {this.state.filteredProjects.map((value, index) => (
              // <GridItem title={value.acf.title} type={value.acf.type} image={value.acf.thumbnail} url={`/portfolio/${value.id}`} key={index} />
              // <Link to={`/portfolio/${value.id}`} className='project' style={{ backgroundImage: `url(${value.acf.thumbnail})` }} key={index}>
              //   <h2>{value.acf.title}</h2>
              //   <h3>{value.acf.type}</h3>
              // </Link>
              <Project key={index} title={value.acf.title} type={value.acf.type} id={value.id} thumbnail={value.acf.thumbnail} />
            ))}
          </div>
          {this.state.filteredProjects.length === 0 && (
            <div className='no-project'>
              Je n’ai pas encore de projet dans cette catégorie ...
              <br />
              <br />
              Choisissez-en une autre
              <br />
              ou contactez moi pour discuter !
            </div>
          )}
          {this.state.filteredProjects.length === 0 ? (
            <div className='contact center'>
              <div className='btn btn-xl' onClick={this.handleContact}>
                <i className='icon icon-enveloppe' />
                <Trans>Contactez-moi</Trans>
              </div>
            </div>
          ) : (
            <div className='contact aligned'>
              <div className='btn btn-xl' onClick={this.handleContact}>
                <i className='icon icon-enveloppe' />
                <Trans>Contactez-moi</Trans>
              </div>
            </div>
          )}
        </div>
        <Book />
      </div>
    );
  }
}

export default translate('translations')(Portfolio);
