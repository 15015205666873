import React, { Component } from 'react';
import DownloadIcon from '../assets/images/download_icon.svg';
import { translate, Trans } from 'react-i18next';
import ProviderOptions from '../providers/options';

class Book extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pressBook: null,
    };

    ProviderOptions.getOptions().then(data => {
      const portfolio = data.data.acf.portfolio;
      this.setState({
        pressBook: portfolio,
      });
    });
  }
  render() {
    return (
      <section className='home__book'>
        <div className='content'>
          {this.state.pressBook ? (
            <div>
              <h3>
                <Trans>Téléchargez mon Press Book</Trans>
                <br />
                <span>
                  <Trans>contenant tous mes projets</Trans>
                </span>
              </h3>
              <a href={this.state.pressBook} className='btn-text' rel='noopener noreferrer' target='_blank'>
                <img src={DownloadIcon} />
                <Trans>Télécharger</Trans>
              </a>
            </div>
          ) : (
            <div>
              <h3>
                <Trans>Retrouvez prochainement mon Press Book</Trans>
                <br />
                <span>
                  <Trans>contenant tous mes projets</Trans>
                </span>
              </h3>
            </div>
          )}
        </div>
      </section>
    );
  }
}

export default translate('translations')(Book);
