import React, { Component } from 'react';
import { Trans, translate } from 'react-i18next';
import ProviderOptions from '../providers/options';

class References extends Component {
  constructor(props) {
    super(props);

    this.state = {
      references: [],
    };

    ProviderOptions.getOptions().then(res => {
      this.setState({
        references: res.data.acf.references,
      });
    });
  }

  render() {
    return (
      <section className='references'>
        <div className='content'>
          <h2 className=''>Références</h2>
        </div>
        <div className='white'>
          <div className='content'>
            <div className='references-list'>
              {this.state.references.length > 0 &&
                this.state.references.map((reference, index) => (
                  <div className='reference' key={index}>
                    <img src={reference.image} alt='' />
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default translate('translations')(References);
