import React, { Component } from 'react';
import Logo from '../assets/images/logo_white.svg';
import { NavLink } from 'react-router-dom';
import SoundCloudLogo from '../assets/images/logo_soundcloud.svg';
import FacebookLogo from '../assets/images/logo_facebook.svg';
import * as emailjs from 'emailjs-com';
import { translate, Trans } from 'react-i18next';

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showContact: false,
      showMenu: false,
      message: '',
      name: '',
      subject: '',
      email: '',
      i18n: this.props.i18n,
    };
    this.handleContactClick = this.handleContactClick.bind(this);
    this.handleMenuOpen = this.handleMenuOpen.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.closeContact = this.closeContact.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    window.addEventListener('contact:open', () => {
      this.handleContactClick();
    });
    emailjs.init('user_ULXPHBLOdfltaxUlVqiG1');
  }

  componentDidMount() {
    const currentLanguage = document.querySelector('.languages-switcher').querySelector(`li[data-language="${this.state.i18n.language}"]`);
    this.selectedLanguage(currentLanguage);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  handleContactClick() {
    this.setState({
      showContact: !this.state.showContact,
      showMenu: true,
    });
  }

  closeContact() {
    this.setState(
      {
        showContact: false,
        showMenu: false,
      },
      () => {
        document.body.classList.toggle('menu--opened', this.state.showContact || this.state.showMenu);
      },
    );
  }

  handleMenuOpen() {
    this.setState(
      {
        showMenu: !this.state.showMenu,
      },
      () => {
        document.body.classList.toggle('menu--opened', this.state.showContact || this.state.showMenu);
      },
    );
  }

  submitForm() {
    if (this.state.name && this.state.email && this.state.subject && this.state.message) {
      this.showFormError();
      if (/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/.test(this.state.email)) {
        this.hideMessages();
        document.querySelector('.contact-message.info').style.display = 'block';
        emailjs
          .send('therence', 'contact_th_rence', {
            email: this.state.email,
            name: this.state.name,
            subject: this.state.subject,
            message: this.state.message,
          })
          .then(
            response => {
              this.hideMessages();
              document.querySelector('.contact-message.success').style.display = 'block';
            },
            error => {
              this.hideMessages();
              document.querySelector('.contact-message.error.error-unknown').style.display = 'block';
            },
          );
      } else {
        this.showFormError();
        this.hideMessages();
        document.querySelector('#contact input[name="email"]').classList.add('error');
        document.querySelector('.contact-message.error.error-email').style.display = 'block';
      }
    } else {
      this.showFormError();
      this.hideMessages();
      document.querySelector('.contact-message.error.error-form').style.display = 'block';
    }
  }

  showFormError() {
    document.querySelectorAll('#contact input, #contact textarea').forEach(item => {
      if (!item.value) {
        item.classList.add('error');
      } else {
        item.classList.remove('error');
      }
    });
  }

  hideMessages() {
    var messages = document.querySelectorAll('.contact-message');
    for (var i = 0; i < messages.length; i++) {
      messages[i].style.display = 'none';
    }
  }

  changeLanguage(e, language) {
    this.selectedLanguage(e.target);
    this.state.i18n.changeLanguage(language);
  }

  selectedLanguage(element) {
    document.querySelectorAll('.languages-switcher ul li').forEach(element => {
      element.classList.remove('selected');
    });
    if (element) {
      element.classList.add('selected');
    }
  }

  render() {
    return (
      <div>
        <div id='contact' className={this.state.showContact ? 'opened' : ''}>
          <div className='contact__overlay' onClick={this.handleContactClick} />
          <div className='contact__window'>
            <div className='navigation'>
              <div className='back' onClick={this.handleContactClick}>
                <Trans>Retour</Trans>
              </div>
              <div className='close' onClick={this.closeContact}>
                <Trans>Fermer</Trans>
              </div>
            </div>
            <h1>
              <Trans>Contact</Trans>
            </h1>
            <div className='contact-message error error-form'>Oups ... Merci de remplir tous les champs ... </div>
            <div className='contact-message error error-unknown'>Oups ... Merci de remplir tous les champs ... </div>
            <div className='contact-message error error-email'>Oups ... Merci de renseigner une adresse email valide ... </div>
            <div className='contact-message success'>Merci pour votre message, j’y répondrai au plus vite !</div>
            <div className='contact-message info'>Envoi en cours</div>
            <form action='#'>
              <div className='input input--50'>
                <input type='text' id='name' value={this.state.name} onChange={this.handleInputChange} name='name' required />
                <label htmlFor='name'>
                  <Trans>Nom et prénom</Trans>
                </label>
              </div>
              <div className='input input--50'>
                <input type='text' id='email' value={this.state.email} onChange={this.handleInputChange} name='email' required />
                <label htmlFor='email'>
                  <Trans>Mail</Trans>
                </label>
              </div>
              <div className='input'>
                <input type='text' id='subject' value={this.state.subject} onChange={this.handleInputChange} name='subject' required />
                <label htmlFor='subject'>
                  <Trans>Objet</Trans>
                </label>
              </div>
              <div className='input textarea'>
                <textarea name='message' id='message' value={this.state.message} onChange={this.handleInputChange} cols='30' rows='5' required />
                <label htmlFor='message'>
                  <Trans>Message</Trans>
                </label>
              </div>
              <div className='center'>
                <div className='btn btn-xl btn-inverse' onClick={this.submitForm}>
                  <i className='icon icon-avion' />
                  <Trans>Envoyer !</Trans>
                </div>
              </div>
              <ul className='networks'>
                <li>
                  <a href='https://soundcloud.com/therence-guillerme'>
                    <img src={SoundCloudLogo} className='soundcloud' alt='Soundcloud' />
                  </a>
                </li>
                <li>
                  <a href='https://www.facebook.com/TherenceGuillermeComposition/?modal=admin_todo_tour'>
                    <img src={FacebookLogo} className='facebook' alt='Facebook' />
                  </a>
                </li>
              </ul>
            </form>
          </div>
        </div>
        <header className='page__header'>
          <nav>
            <div id='logo'>
              <NavLink activeClassName='selected' to='/'>
                <img src={Logo} alt='' />
              </NavLink>
            </div>
            <div className='burger' onClick={this.handleMenuOpen}>
              <div className='wrapper'>
                <span />
              </div>
            </div>
            <div className='languages-switcher'>
              <ul>
                <li data-language='en' onClick={e => this.changeLanguage(e, 'en')}>
                  EN
                </li>
                <li data-language='fr' onClick={e => this.changeLanguage(e, 'fr')}>
                  FR
                </li>
              </ul>
            </div>
            <div className={this.state.showMenu ? 'menu menu__opened' : 'menu'}>
              <div className='close' onClick={this.handleMenuOpen}>
                <Trans>Fermer</Trans>
              </div>
              <ul>
                <li>
                  <NavLink activeClassName='selected' to='/portfolio' onClick={this.handleMenuOpen}>
                    <Trans>Portfolio</Trans>
                  </NavLink>
                </li>
                <li>
                  <NavLink activeClassName='selected' to='/about' onClick={this.handleMenuOpen}>
                    <Trans>À propos</Trans>
                  </NavLink>
                </li>
                <li>
                  <NavLink activeClassName='selected' to='/references' onClick={this.handleMenuOpen}>
                    <Trans>Références</Trans>
                  </NavLink>
                </li>
                <li>
                  <NavLink activeClassName='selected' to='/news' onClick={this.handleMenuOpen}>
                    <Trans>Actualités</Trans>
                  </NavLink>
                </li>
                <li>
                  <NavLink activeClassName='selected' to='#' onClick={this.handleContactClick}>
                    <Trans>Contact</Trans>
                  </NavLink>
                </li>
              </ul>
              <ul className='networks'>
                <li>
                  <a href='https://soundcloud.com/therence-guillerme'>
                    <img src={SoundCloudLogo} className='soundcloud' alt='Soundcloud' />
                  </a>
                </li>
                <li>
                  <a href='https://www.facebook.com/TherenceGuillermeComposition/?modal=admin_todo_tour'>
                    <img src={FacebookLogo} className='facebook' alt='Facebook' />
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </header>
      </div>
    );
  }
}

export default translate('translations')(Navigation);
