import axios from 'axios';

class ProviderProjects {
  BASE_URL = 'https://api.therenceguillerme.fr/wp-json/acf/v3';

  getProjects() {
    return axios.get(`${this.BASE_URL}/projets?per_page=100`);
  }

  getProject(id) {
    return axios.get(`${this.BASE_URL}/projets/${id}`);
  }
}

export default new ProviderProjects();
