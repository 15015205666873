import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Project extends Component {
  render() {
    return (
      <Link to={`/portfolio/${this.props.id}`} className='project' style={{ backgroundImage: `url(${this.props.thumbnail})` }} key={this.props.id}>
        <div className='titles'>
          <h3>{this.props.type}</h3>
          <h2>{this.props.title}</h2>
        </div>
      </Link>
    );
  }
}
