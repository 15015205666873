import styled from 'styled-components';
import React, { Component } from 'react';
import NewsProvider from '../providers/news';
import { Link } from 'react-router-dom';

const News = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media all and (max-width: 1024px) {
    padding: 0 20px;
  }
`;

const Title = styled.h1`
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  line-height: 74px;
  font-size: 70px;
  margin-bottom: 13px;
  color: #fff;/#0019ff;
  max-width: 648px;
  @media all and (max-width: 1024px) {
    font-size: 48px;
    margin-bottom: 0px;
  }
`;

const Subtitle = styled.h2`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-size: 18px;
  margin-left: 25px;

  color: #fff;/#0019ff;

  @media all and (max-width: 1024px) {
    font-size: 16px;
  }
`;

const Content = styled.div`
  position: relative;
  margin-top: 60px;
  width: 100%;
`;

const TopContent = styled.div`
  position: relative;
  max-width: 731px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  z-index: 1;
  min-height: 600px;
`;

const HeaderBackground = styled.div`
  //background: url(${require('../assets/images/portfolio_detail_background.svg')});
  height: 498px;
  width: 525px;
  position: absolute;
  top: -40px;
  left: 300px;
  @media all and (max-width: 1024px) {
    left: -302px;
  }
`;

const HeaderLine = styled.div`
  width: 1600px;
  height: 11px;
  margin-left: -1450px;
  background: #0019ff;
`;

const HeaderFooter = styled.div`
  display: flex;
  align-items: center;
`;

const NewsDescription = styled.div`
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  font-size: 14px;

  p {
    margin: 25px 0;
    color: #fff;
  }

  a {
    color: #fff;
  }

  h2 {
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    font-size: 24px;

    color: #fff;/#0019ff;
    margin-top: 50px;
    margin-bottom: 30px;
  }

  figure {
    margin-bottom: 20px;
    img {
      width: 100%;
      margin-bottom: 10px;
    }

    figcaption {
      font-style: italic;
      font-weight: 500;
      line-height: normal;
      font-size: 14px;
      text-align: left;

      color: rgba(0, 0, 0, 0.5);
    }
  }

  color: #000000;
  margin-bottom: 50px;
`;

// const BottomButton = styled(AngleButton)`
//   align-self: center;
// `;

export default class NewsDetail extends Component {
  constructor(props) {
    super(props);
    this.state = { newsItem: null };

    NewsProvider.getNews(this.props.match.params.id).then(res => {
      this.setState({ newsItem: res.data });
    });
    this.decodeHTML = this.decodeHTML.bind(this);
  }

  decodeHTML(html) {
    const txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
  }

  render() {
    if (this.state.newsItem === null) {
      return <article />;
    }

    return (
      <News>
        <header>
          <Title>{this.decodeHTML(this.state.newsItem.title.rendered)}</Title>
          <HeaderFooter>
            <HeaderLine />
            <Subtitle>
              {new Date(this.state.newsItem.date).toLocaleDateString('fr-FR', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            </Subtitle>
          </HeaderFooter>
        </header>
        <Content>
          <HeaderBackground />
          <TopContent>
            <NewsDescription dangerouslySetInnerHTML={{ __html: this.state.newsItem.content.rendered }} />
            <Link to='/news' className='btn'>
              <i className='icon icon-fleche_gauche' />
              Retour aux actus
            </Link>
          </TopContent>
        </Content>
      </News>
    );
  }
}
