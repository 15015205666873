import React, { Component } from "react";

import SoundCloudLogo from "../assets/images/logo_soundcloud.svg";
import FacebookLogo from "../assets/images/logo_facebook.svg";

class App extends Component {
  render() {
    return (
      <div className="page contact">
        <h1>Contact</h1>
        <div className="contact__content">
          <header className="contact__header">
            <svg
              className="contact__background"
              width="74"
              height="74"
              viewBox="0 0 74 74"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M74 73.2559L0 0V7.24011L61.0487 73.2559H74Z"
                fill="#0019FF"
                fillOpacity="0.1"
              />
              <path
                d="M41 73.2557L0 29.3022V42.9378L30.2394 73.2557H41Z"
                fill="#0019FF"
                fillOpacity="0.1"
              />
            </svg>
            <h2 className="contact__subtitle">Une question ?</h2>
            <div className="contact__separator" />
          </header>
          <div className="form__wrapper">
            <form>
              <div className="form__row">
                <div className="input">
                  <label>Nom et prénom</label>
                  <input
                    onFocus={this.onInputClick.bind(this)}
                    onBlur={this.onInputBlur.bind(this)}
                  />
                </div>
                <div className="input">
                  <label>Mail</label>
                  <input
                    onFocus={this.onInputClick.bind(this)}
                    onBlur={this.onInputBlur.bind(this)}
                  />
                </div>
              </div>
              <div className="form__row">
                <div className="input">
                  <label>Objet</label>
                  <input
                    onFocus={this.onInputClick.bind(this)}
                    onBlur={this.onInputBlur.bind(this)}
                  />
                </div>
              </div>
              <div className="form__row">
                <div className="input">
                  <label>Message</label>
                  <textarea
                    onFocus={this.onInputClick.bind(this)}
                    onBlur={this.onInputBlur.bind(this)}
                  />
                </div>
              </div>
              <div className="form__row">
                <input type="submit" value="Envoyer" />
              </div>
            </form>
          </div>
          <ul className="contact__socials">
            <li className="contact__social">
              <a href="https://soundcloud.com/therence-guillerme">
                <img
                  src={SoundCloudLogo}
                  className="soundcloud"
                  alt="Soundcloud"
                />
              </a>
            </li>
            <li className="contact__social">
              <a href="https://www.facebook.com/TherenceGuillermeComposition/?modal=admin_todo_tour">
                <img src={FacebookLogo} className="facebook" alt="Facebook" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  }

  onInputClick(e) {
    e.persist();
    e.target.parentNode.classList.add("focus");
  }

  onInputBlur(e) {
    e.persist();
    if (e.target.value.trim().length === 0) {
      e.target.value = null;

      e.target.parentNode.classList.remove("focus");
    }
  }
}

export default App;
