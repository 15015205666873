import styled from 'styled-components';
import React, { Component } from 'react';
import Navigation from './Navigation';
import Home from './Home';
import Portfolio from './Portfolio';
import Footer from './Footer';
import About from './About';
import News from './News';
import References from './References';
import Contact from './Contact';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PortfolioDetail from '../pages/PortfolioDetail';
import { I18nextProvider } from 'react-i18next';
import i18n from '../i18n';
// import Konami from 'perfect-konami.js';

import NewsDetail from '../pages/NewsDetail';
import Mentions from './Mentions';
import ScrollToTop from './ScrollToTop';

const Page = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

class App extends Component {
  constructor(props) {
    super(props);
    // const konami = new Konami();
    // konami.init();
  }

  render() {
    return (
      <I18nextProvider i18n={i18n}>
        <Router>
          <ScrollToTop>
            <div id='app'>
              <Navigation i18n={i18n} />
              <Page>
                <Switch>
                  <Route exact path='/' component={Home} />
                  <Route path='/news/:id' component={NewsDetail} />
                  <Route path='/portfolio/:id' component={PortfolioDetail} />
                  <Route path='/portfolio' component={Portfolio} />
                  <Route path='/about' component={About} />
                  <Route act path='/news' component={News} />
                  <Route path='/mentions-legales' component={Mentions} />
                  <Route path='/contact' component={Contact} />
                  <Route path='/references' component={References} />
                </Switch>
              </Page>
              <Footer />
            </div>
          </ScrollToTop>
        </Router>
      </I18nextProvider>
    );
  }
}

export default App;
