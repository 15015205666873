import styled from 'styled-components';
import React, { Component } from 'react';

const DetailVideo = styled.article`
  display: inline-flex;
  flex-direction: column;
  width: 100%;
`;

const Credits = styled.h3`
  font-family: Raleway;
  font-style: italic;
  font-weight: 500;
  line-height: normal;
  font-size: 14px;

  color: rgba(255, 255, 255, 0.5);
  margin-top: -10px;
`;

class Video extends Component {
  render() {
    return (
      <div className={this.props.className} style={{ width: '100%' }}>
        <iframe
          title='Vimeo video'
          style={{ width: '100%' }}
          src={`https://player.vimeo.com/video/${this.props.source.substring(this.props.source.lastIndexOf('/') + 1)}`}
          width='804'
          height='350'
          frameBorder='0'
        />
      </div>
    );
  }
}

const StyledVideo = styled(Video)`
  margin-top: 28px;
  margin-bottom: 21px;
`;

export default class PortfolioDetailVideo extends Component {
  render() {
    return (
      <DetailVideo>
        <div style={{ width: '100%' }}>
          <StyledVideo source={this.props.source} />
          {this.props.credits.length > 0 && <Credits>Crédits : {this.props.credits}</Credits>}
        </div>
      </DetailVideo>
    );
  }
}
