import React, { Component } from 'react';
import styled from 'styled-components';
import GridItem from './GridItem';
import NewsProvider from '../providers/news';
import * as moment from 'moment';
import { Trans, translate } from 'react-i18next';

const NewsSection = styled.section`
  width: 100%;
  background-image: url(${require('../assets/images/pattern_fond.png')});
  background-size: contain;
  background-position-y: 480px;
  background-repeat: repeat-y;
  width: 100%;
  @media all and (max-width: 380px) {
    background-image: none;
  }
`;

const Content = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  margin-top: 250px;
  margin-bottom: 50px;
  .contact {
    padding-top: 60px;
  }

  @media all and (max-width: 1025px) {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 150px;
    margin-bottom: 0;
  }

  div:last-child:nth-child(odd) {
    &.aligned {
      margin-top: 0;
      width: 50%;
      flex-basis: 50%;
      margin-left: auto;
      text-align: left;
    }
  }

  div:last-child:nth-child(even) {
    &.aligned {
      margin-top: 0;
      width: 50%;
      flex-basis: 50%;
      margin-right: auto;
      text-align: right;
    }
  }

  @media all and (max-width: 1025px) {
    .contact {
      margin: 0;
      width: 100% !important;
      flex-basis: 100% !important;
      padding-top: 0;
    }
  }
`;

class News extends Component {
  constructor(props) {
    super(props);

    this.state = {
      news: [],
    };

    NewsProvider.getAllNews().then(res => {
      const news = res.data;
      news.sort((a, b) => {
        const left = moment(a.acf.date, 'DD/MM/YYYY');
        const right = moment(b.acf.date, 'DD/MM/YYYY');
        return left < right ? 1 : left > right ? -1 : 0;
      });
      this.setState({ news });
    });

    this.formatDate = this.formatDate.bind(this);
    this.handleContact = this.handleContact.bind(this);
  }

  handleContact() {
    const event = new Event('contact:open');
    window.dispatchEvent(event);
  }

  formatDate(date) {
    const d = new Date(date);
    return ('0' + d.getDate()).slice(-2) + '/' + ('0' + d.getMonth() + 1).slice(-2) + '/' + d.getFullYear();
  }

  render() {
    return (
      <NewsSection id='news'>
        <div className='header'>
          <div className='content'>
            <h1>
              <Trans>Actualités</Trans>
            </h1>
          </div>
        </div>
        <Content>
          {this.state.news.map(news => (
            <GridItem
              title={news.title.rendered}
              type={this.formatDate(news.date)}
              image={news.featured_image_url}
              url={`/news/${news.id}`}
              key={news.id}
            />
          ))}
          {this.state.news.length === 0 ? (
            <div className='contact center'>
              <div className='btn btn-xl' onClick={this.handleContact}>
                <i className='icon icon-enveloppe' />
                <Trans>Contactez-moi</Trans>
              </div>
            </div>
          ) : (
            <div className='contact aligned'>
              <div className='btn btn-xl' onClick={this.handleContact}>
                <i className='icon icon-enveloppe' />
                <Trans>Contactez-moi</Trans>
              </div>
            </div>
          )}
        </Content>
      </NewsSection>
    );
  }
}

export default translate('translations')(News);
