import styled from 'styled-components';
import React, { Component } from 'react';

const Description = styled.p`
  font-family: Raleway;
  font-style: italic;
  font-weight: 500;
  line-height: normal;
  font-size: 14px;
  width: 781px;

  color: rgba(255, 255, 255, 0.5);
  margin-top: 39px;

  @media all and (max-width: 1024px) {
    width: auto;
  }
`;

const Player = styled.div`
  margin-top: 27px;
`;

const PlayerWrapper = styled.div`
  width: 100%;
`;

export default class PortfolioAudioPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      iframe: null,
    };
  }

  componentDidMount() {
    /* getEmbeded(this.props.source).then(html => {
      this.setState({
        iframe: html
      });
    }); */
  }

  render() {
    return (
      <PlayerWrapper>
        {/*  <Player
          height="120"
          dangerouslySetInnerHTML={{ __html: this.state.iframe }}
        /> */}
        <Player>
          <iframe title='Soundcloud sound' width='100%' height='300' scrolling='no' frameBorder='no' allow='autoplay' src={this.props.source} />
        </Player>
        {this.props.credits.length > 0 && <Description>Crédits : {this.props.credits}</Description>}
      </PlayerWrapper>
    );
  }
}
