import React, { Component } from 'react';
import PhotoAbout1 from "../assets/images/about_photo_1.jpg";
import PhotoAbout2 from "../assets/images/about_photo_2.jpg";
import PhotoAbout4 from "../assets/images/about_photo_4.jpg";
import { translate, Trans } from "react-i18next";
import PageProvider from "../providers/pages";

class About extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: {
        part: []
      }
    };

    PageProvider.getPage(403)
    .then(res => {
      const page = res.data.acf;
      this.setState({page});
      console.log(page);
    })

    this.handleContact = this.handleContact.bind(this)
  }

  handleContact() {
    const event = new Event('contact:open');
    window.dispatchEvent(event);
  }

  render() {
    return (
      <div id="about" className="page">
        <div className="header">
          <div className="content">
            <h1>
              <Trans>
                À propos
              </Trans>
            </h1>
          </div>
        </div>
        <div className="container">
          {this.state.page.part.map((part, index) => (
            <div key={index}>
              <section>
                <div className="column">
                  <div className="photo__effect">
                    <img src={part.image} alt="Visuel"/>
                  </div>
                </div>
                <div className="column">
                  <div className="info">
                    <h2 dangerouslySetInnerHTML={{ __html: part.subtitle_1 }}></h2>
                    <p dangerouslySetInnerHTML={{ __html: part.content_1 }}></p>
                  </div>
                  <div className="info">
                  <h2 dangerouslySetInnerHTML={{ __html: part.subtitle_2 }}></h2>
                  <p dangerouslySetInnerHTML={{ __html: part.content_2 }}></p>
                  </div>
                </div>
              </section>
              {index === 0 ? (

              <div className="jumbotron" style={{backgroundImage: `url(${this.state.page.image_1})`}}>
                <img src={this.state.page.image_2} alt="" className="jumbo"/>
              </div>
              ) : ''}
            </div>
          ))}
          <div className="contact center">
            <div className="btn btn-xl" onClick={this.handleContact}><i className="icon icon-enveloppe"/>Contactez-moi</div>
          </div>
        </div>
      </div>
    );
  }
}

export default translate("translations")(About);
