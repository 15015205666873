import styled from 'styled-components';
import React, { Component } from 'react';
import PortfolioDetailVideo from '../components/PortfolioDetail/PortfolioDetailVideo';
import PortfolioImageSlider from '../components/PortfolioDetail/PortfolioImageSlider';
import PortfolioAudioPlayer from '../components/PortfolioDetail/PortfolioAudioPlayer';
import ProviderProjects from '../providers/projects';
import { Link } from 'react-router-dom';
import { Trans, translate } from 'react-i18next';

const Title = styled.h1`
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  line-height: 74px;
  font-size: 70px;
  margin-bottom: 13px;
  color: #fff;
  @media all and (max-width: 768px) {
    font-size: 38px;
    margin-bottom: 0px;
    line-height: normal;
  }
`;

const Subtitle = styled.h2`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-size: 18px;
  margin-left: 25px;
  text-transform: capitalize;

  color: #fff;
  @media all and (max-width: 380px) {
    font-size: 16px;
  }
`;

const HeaderLine = styled.div`
  width: 1600px;
  height: 11px;
  margin-left: -1450px;
  background: #0019ff;
`;

const HeaderFooter = styled.div`
  display: flex;
  align-items: center;
`;

const Content = styled.div`
  position: relative;
  margin-top: 143px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  @media all and (max-width: 1024px) {
    margin-top: 59px;
    padding: 20px;
  }
`;

const DetailSection = styled.section`
  width: 100%;

  @media all and (max-width: 1024px) {
    width: 100%;
    /*  padding: 0 20px; */
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

// const BottomButton = styled(AngleButton)`
//   margin-top: 71px;
//   align-self: center;
//   margin: 0 20px;
//   text-decoration: none;
// `;

const StyledImageSlider = styled(PortfolioImageSlider)`
  margin-top: 192px;
  width: 100%;
  margin-bottom: 70px;

  @media all and (max-width: 380px) {
    margin-top: 59px;
  }
  @media all and (max-width: 380px) {
    margin-top: 91px;
  }
`;

const TitleArticle = styled.h1`
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  font-size: 24px;
  max-width: 603px;

  color: #fff;
  margin-bottom: 17px;
`;

const DescriptionArticle = styled.p`
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  font-size: 14px;
  max-width: 731px;
  color: #fff;
`;

const DetailHeader = styled.header`
  width: 731px;
  margin: 0 auto;
  @media all and (max-width: 1024px) {
    width: 100%;
  }
  @media all and (max-width: 1024px) {
    padding: 0 20px;
  }
`;

const TopContent = styled.div`
  width: 100%;
  max-width: 625px;
  margin: 0 auto;
  z-index: 1;
  min-height: 600px;
`;

const HeaderBackground = styled.div`
  position: absolute;
  top: -40px;
  left: 0;
  right: 50%;
  background-color: #232323;
  width: 50%;

  @media all and (max-width: 1024px) {
    left: -302px;
  }
`;

class PortfolioDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      project: null,
      backgroundHeight: 0,
    };

    ProviderProjects.getProject(this.props.match.params.id).then(res => {
      this.setState({
        project: res.data.acf,
      });
    });
    this.mediaElement = React.createRef();
    this.decodeHTML = this.decodeHTML.bind(this);
  }

  decodeHTML(html) {
    const txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
  }

  componentDidMount() {
    console.log(this.mediaElement);
    const backgroundHeight = this.mediaElement.offsetTop;
    console.log('BackgroundHeight', backgroundHeight);
    this.setState({ backgroundHeight });
  }

  render() {
    if (this.state.project === null) {
      return <article />;
    }
    let media = null;
    if (this.state.project.source.includes('soundcloud')) {
      media = (
        <PortfolioAudioPlayer
          ref={this.mediaElement}
          credits={this.state.project.credits}
          source={this.state.project.source}
        />
      );
    } else {
      media = (
        <PortfolioDetailVideo
          ref={this.mediaElement}
          credits={this.state.project.credits}
          source={this.state.project.source}
        />
      );
    }

    let slider = null;

    if (this.state.project.gallery) {
      slider = <StyledImageSlider images={this.state.project.gallery} />;
    }

    return (
      <DetailSection>
        <DetailHeader>
          <Title>{this.decodeHTML(this.state.project.title)}</Title>
          <HeaderFooter>
            <HeaderLine />
            <Subtitle>{this.state.project.type}</Subtitle>
          </HeaderFooter>
        </DetailHeader>
        <Content>
          <HeaderBackground
            style={{
              bottom: this.mediaElement.current
                ? this.mediaElement.current.offsetTop
                : 1200,
            }}
          />
          <TopContent>
            <TitleArticle>
              {this.state.project.directors}
              <br />
              {this.state.project.school}
            </TitleArticle>
            <DescriptionArticle
              dangerouslySetInnerHTML={{ __html: this.state.project.synopsis }}
            />
            {media}
          </TopContent>
          {slider}
          <Link to="/portfolio" className="btn">
            <i className="icon icon-fleche_gauche" />
            <Trans>Retour au Portfolio</Trans>
          </Link>
        </Content>
      </DetailSection>
    );
  }
}

export default translate('translations')(PortfolioDetail);
