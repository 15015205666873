import React, { Component } from 'react';
import { Link } from "react-router-dom";
import AppButton from "./AppButton";
import {Trans, translate} from "react-i18next";

class GridItem extends Component {
  constructor(props) {
    super(props)
    this.decodeHTML = this.decodeHTML.bind(this)
  }

  decodeHTML(html) {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  }

  render() {
    return (
      <div className="grid-item">
        <h3>{this.props.type}</h3>
        <h2>{this.decodeHTML(this.props.title)}</h2>
        <AppButton href={this.props.url} className="grid-item__button" style={{}}>
          <Trans>
            Découvrir
          </Trans>
        </AppButton>
        <Link to={this.props.url}>
          <div className="thumbnail" style={{backgroundImage: `url(${this.props.image})`}}>
          </div>
        </Link>
        <div className="grid-item__rect" />
      </div>
    );
  }
}

export default translate("translations")(GridItem);
