import axios from 'axios';

class ProviderNews {
  BASE_URL = 'https://api.therenceguillerme.fr/wp-json/wp/v2';

  getAllNews() {
    return axios.get(`${this.BASE_URL}/posts?_embed&per_page=100`);
  }

  getNews(id) {
    return axios.get(`${this.BASE_URL}/posts/${id}?_embed&per_page=100`);
  }
}

export default new ProviderNews();
